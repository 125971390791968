<template>
  <div class="index container">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
          >Home</v-breadcrumbs-item
        >
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'inventoryReservation', params: {} }"
          active
        >
          Inventory Reservation
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <div class="d-flex flex-column flex-lg-row">
        <v-text-field
          dense
          v-model.number="searchTerms"
          @keyup.enter="search"
          @click:clear="clearSearch"
          @click:append="search"
          placeholder="Search by product name or document number"
          append-icon="mdi-magnify"
          outlined
          clearable
          label="Search"
        />
      </div>

      <div class="table-container">
        <v-data-table
          :headers="headers"
          :items="inventoryReservationList"
          :items-per-page="pagination.pageSize"
          :loading="!inventoryReservationProcessed"
          :server-items-length="pagination.totalSize"
          :page="pagination.current"
          :disable-pagination="
            !inventoryReservationList || inventoryReservationList.length < 1
          "
          :hide-default-header="
            !inventoryReservationList || inventoryReservationList.length < 1
          "
          :hide-default-footer="
            !inventoryReservationList || inventoryReservationList.length < 1
          "
          :options.sync="options"
          item-key="id"
          class="elevation-1"
          @update:options="pageChanged"
        >
          <template v-slot:item.processWorksheetId="{ item }">
            <a @click="openWorksheet(item.processWorksheetId)">
              {{ item.worksheetName }} {{ item.processWorksheetId }}
            </a>
            <div class="text-caption" v-if="item.processName">
              {{ item.processName }}
            </div>
          </template>
          <template v-slot:item.inventoryId="{ item }">
            <a @click="openInventory(item.inventoryId)">{{
              item.inventoryId
            }}</a>
            <span class="text-caption" v-if="item.inventoryUnitId">
              / {{ item.inventoryUnitCode }}
            </span>
          </template>
          <template v-slot:item.createTime="{ item }">
            {{ item.createTime | formatDateYear }}
          </template>
          <template v-slot:item.quantity="{ item }">
            {{ item.quantity | formatNumber }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="remove(item)" title="Delete">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </div>
    <confirmation-dialog ref="confirm" />
    <inventory-lookup
      :activated.sync="lookupInventory"
      v-model="selectedInventory"
    />

    <v-dialog v-model="worksheetDialog" style="z-index: 1000">
      <v-card>
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="worksheetDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <worksheet-card
            :worksheet="selectedWorksheet"
            :key="selectedWorksheet"
            :showEditButtons="false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const InventoryLookup = () =>
  import("@/components/inventory/InventoryLookup.vue");
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");

export default {
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 100,
      },
      options: {
        //define default sorting option here.
        sortBy: [],
        sortDesc: [],
      },
      inventoryReservationList: [],
      showParentPage: true,

      headers: [
        {
          text: "Reserved by Worksheet",
          value: "processWorksheetId",
          align: "start",
          sortable: true,
        },
        {
          text: "Product",
          value: "productName",
          align: "start",
          sortable: false,
        },
        {
          text: "Inventory / (Unit)",
          value: "inventoryId",
          align: "start",
          sortable: true,
        },
        {
          text: "Quantity",
          value: "quantity",
          align: "start",
          sortable: true,
        },
        {
          text: "Reserved On",
          value: "createTime",
          align: "start",
          sortable: true,
        },

        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      sort: {
        key: null,
      },
      searchTerms: null,
      lookupInventory: false,
      selectedInventory: null,
      worksheetDialog: false,
      selectedWorksheet: null,
    };
  },
  components: {
    InventoryLookup,
    WorksheetCard,
  },
  computed: {
    ...mapGetters("inventoryReservation", [
      "inventoryReservationProcessed",
      "getInventoryReservations",
    ]),
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("inventoryReservation", [
      "deleteInventoryReservation",
      "fetchInventoryReservations",
    ]),
    view(item) {
      this.$router.push({
        name: "inventoryReservationView",
        params: { id: item.id },
      });
    },
    openWorksheet(worksheetId) {
      this.worksheetDialog = true;
      this.selectedWorksheet = worksheetId;
    },
    openInventory(inventoryId) {
      this.selectedInventory = inventoryId;
      this.lookupInventory = true;
    },
    edit(item) {
      this.$router.push({
        name: "inventoryReservationEdit",
        params: { id: item.id },
      });
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this inventory reservation?"
      );
      if (confirm) {
        this.deleteInventoryReservation(item.id)
          .then(() => {
            this.addSuccessMessage(
              "inventory reservation was deleted successfully"
            );
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    getResources() {
      //default to sorting by ID desc if not defined
      const sortBy =
        this.options["sortBy"] && this.options["sortBy"].length > 0
          ? this.options.sortBy[0]
          : "id";
      const sortDesc =
        this.options["sortDesc"] && this.options["sortDesc"].length > 0
          ? this.options.sortDesc[0]
          : true;
      const params = {
        page:
          this.pagination.current && this.pagination.current > 0
            ? this.pagination.current - 1
            : 0,
        size: this.pagination.pageSize,
        sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
        productName: this.searchTerms,
      };
      this.fetchInventoryReservations(params)
        .then((response) => {
          if (response) {
            this.pagination.totalSize = response.totalElements;
            this.inventoryReservationList = response.content;
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    showParent(show) {
      this.showParentPage = show;
    },
    pageChanged(newOptions) {
      this.options = newOptions; // Save the new options

      // Update the pagination data from the options
      this.pagination.current = newOptions.page;
      this.pagination.pageSize = newOptions.itemsPerPage;

      this.getResources();
    },
    async search() {
      await this.getResources();

      // reset options.page to first page on search
      this.options.page = 1;
    },
    clearSearch() {
      this.searchTerms = null;
      this.getResources();
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
};
</script>

<style scoped>
a {
  color: blue !important;
}
</style>